import Cookies from 'js-cookie';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { CookieKeys } from 'services/cookies/types';
import { REACT_APP_GRAPHQL_GATEWAY_API } from 'environment';

const httpLink = createHttpLink({
  uri: REACT_APP_GRAPHQL_GATEWAY_API
});

const authLink = setContext((_, { headers }) => {
  const accessToken = Cookies.get(CookieKeys.apiAccessToken);
  const userId = Cookies.get(CookieKeys.userId);
  const deviceIdCookie = Cookies.get(CookieKeys.deviceId);

  return {
    headers: {
      ...headers,
      'X-Grover-Language': Cookies.get('locale') ?? 'en',
      ...(userId && { 'X-Grover-User': userId }),
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...(deviceIdCookie && { 'X-Grover-Device-ID': deviceIdCookie })
    }
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
  defaultOptions: {
    query: { fetchPolicy: 'no-cache', errorPolicy: 'all' },
    mutate: { fetchPolicy: 'no-cache', errorPolicy: 'all' },
    watchQuery: { fetchPolicy: 'no-cache', errorPolicy: 'all' }
  }
});

export default client;
