export enum CookieKeys {
  userId = 'user_id',
  apiRefreshToken = 'api_refresh_token',
  apiAccessToken = 'api_access_token',
  deviceId = 'device_id',
  storeCode = 'x-grover-store'
}

export interface AuthCredentials {
  authToken: string;
  expiresIn?: number;
}
