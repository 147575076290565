import { AxiosRequestConfig } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { axiosRequest } from 'services/axios';
import { setCookieAuth } from 'services/cookies';
import { handleTwoFactorAuthentication, handleError } from './errorHandling';
import { GroverUser, GroverTokensData } from './types';

export interface LoginRequest {
  email: string;
  password: string;
  twoFa_code?: string;
}

interface LoginResponse extends GroverTokensData {
  created_at: number;
  scope: string;
  token_type: string;
}

const saveTokens = (response: GroverTokensData): void => {
  setCookieAuth({
    authToken: response?.access_token,
    expiresIn: response?.expires_in
  });
};

export function getUser(config?: AxiosRequestConfig): Promise<GroverUser> {
  const headers = {
    'X-Grover-Store': 'de'
  };

  return axiosRequest
    .get<{ user: GroverUser }>('api/v1/user', {
      ...config,
      headers
    })
    .then((response) => {
      const user = response.data.user;

      datadogRum.setUser({
        id: user.id.toString(),
        ...(user.company
          ? {
              name: user.company.name,
              companyId: user.company.id.toString()
            }
          : {})
      });

      return user;
    });
}

export function login(data: LoginRequest): Promise<GroverUser> {
  const formData = {
    grant_type: 'password',
    email: data.email,
    password: data.password
  };

  if (data.twoFa_code) {
    formData['2fa_code'] = data.twoFa_code;
  }

  return axiosRequest
    .post<LoginResponse>('api/v1/oauth/token', formData)
    .then((response) => saveTokens(response.data))
    .then(() => getUser())
    .catch(handleTwoFactorAuthentication)
    .catch(handleError);
}
