import { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { CookieKeys } from 'services/cookies/types';

function headersInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  try {
    const accessToken = Cookies.get(CookieKeys.apiAccessToken);
    const userId = Cookies.get(CookieKeys.userId);
    const deviceIdCookie = Cookies.get(CookieKeys.deviceId);

    const headers = {
      ...config.headers,
      ...(userId && { 'X-Grover-User': userId }),
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...(deviceIdCookie && { 'X-Grover-Device-ID': deviceIdCookie })
    };

    return { ...config, headers };
  } catch (error) {
    return config;
  }
}

export { headersInterceptor };
