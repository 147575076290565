import { createGlobalStyle } from 'styled-components';
import { Theme } from 'theme/types';

export const ThemedBody = createGlobalStyle<{ theme: Theme }>`
    body {
        margin: 0;
        padding: 20px;
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        color: ${({ theme }) => theme.colors.Foreground};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;

        * {
            box-sizing: border-box;
        }
    }
`;
