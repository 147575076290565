import axios from 'axios';
import { REACT_APP_GROVER_API } from 'environment';
import { headersInterceptor } from './headersInterceptor';

function createAxiosInstance() {
  const headers = {
    'Content-Type': 'application/json'
  };

  const config = {
    baseURL: REACT_APP_GROVER_API,
    headers
  };

  const axiosInstance = axios.create(config);

  return axiosInstance;
}

const axiosRequest = createAxiosInstance();
axiosRequest.interceptors.request.use(headersInterceptor);

export { axiosRequest };
