import { Children, createContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { Theme } from 'theme/types';

import { ThemedBody } from './styles';

export const ThemeContext = createContext({});

interface Props {
  theme: Theme;
  children: React.ReactNode;
}

export const ThemeProvider = ({ theme, children }: Props): JSX.Element => (
  <ThemeContext.Provider value={theme}>
    <StyledThemeProvider theme={theme}>
      <ThemedBody />
      {Children.only(children)}
    </StyledThemeProvider>
  </ThemeContext.Provider>
);

export default ThemeProvider;
