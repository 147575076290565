import { useEffect, useState } from 'react';
import { getUser } from 'services/grover/user';
import { GroverUser } from 'services/grover/types';

export const useUser = (): {
  user: GroverUser | null;
  loading: boolean;
  loaded: boolean;
} => {
  const [user, setUser] = useState<GroverUser | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getUser()
      .then((data) => setUser(data))
      .catch(() => setUser(null))
      .finally(() => {
        setLoaded(true);
        setLoading(false);
      });
  }, []);

  return { user, loading, loaded };
};
