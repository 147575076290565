import { RumErrorEvent } from '@datadog/browser-rum';

export const filterError = (exception: unknown): boolean => {
  if (exception && typeof exception === 'object' && 'error' in exception) {
    const ddError = (exception as RumErrorEvent).error;

    // Checkout errors
    if (
      ddError.message &&
      (ddError.message.includes('Error: Could not initialize nethone') ||
        ddError.message.includes('Event structure does not match a schema!'))
    ) {
      return true;
    }
  }

  return false;
};
