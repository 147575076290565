import { datadogRum } from '@datadog/browser-rum';

import {
  DD_ENV,
  DD_RUM_APPLICATION_ID,
  DD_RUM_CLIENT_TOKEN,
  DD_VERSION,
  NODE_ENV
} from 'environment';
import { filterError } from './utils';

export const initRUM = (): void => {
  const ddEnv = DD_ENV || NODE_ENV || '';
  if (!['staging', 'production'].includes(ddEnv)) {
    return;
  }

  datadogRum.init({
    applicationId: DD_RUM_APPLICATION_ID,
    clientToken: DD_RUM_CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask',
    env: ddEnv,
    replaySampleRate: ddEnv === 'production' ? 100 : 0,
    sampleRate: 100,
    service: 'business-ui',
    site: 'datadoghq.eu',
    trackInteractions: true,
    version: DD_VERSION,
    beforeSend: (event, context) => {
      if (
        event.type === 'error' &&
        'error' in context &&
        (context.error || event.error) &&
        filterError(context.error || event)
      ) {
        return false;
      }
    }
  });

  datadogRum.startSessionReplayRecording();
};
