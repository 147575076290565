const {
  DD_ENV,
  DD_RUM_APPLICATION_ID,
  DD_RUM_CLIENT_TOKEN,
  DD_VERSION,
  NODE_ENV,
  REACT_APP_GRAPHQL_GATEWAY_API,
  REACT_APP_GROVER_API,
  REACT_APP_SEGMENT_WRITE_KEY,
  PUBLIC_FRONTSHOP_BASE_URL
} = window.configurationConstants || {};

export {
  DD_ENV,
  DD_RUM_APPLICATION_ID,
  DD_RUM_CLIENT_TOKEN,
  DD_VERSION,
  NODE_ENV,
  REACT_APP_GRAPHQL_GATEWAY_API,
  REACT_APP_GROVER_API,
  REACT_APP_SEGMENT_WRITE_KEY,
  PUBLIC_FRONTSHOP_BASE_URL
};
