/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { GroverTwoFaResponse, ErrorResponse, ValidationErrorResponse } from './types';

export const TWO_FA_ERROR_NAME = 'TwoFactorAuthenticationError';
export const GRANT_VALIDATION_ERROR = 'GrantValidationError';
export const INVALID_CODE_ERROR = 'InvalidCodeError';
export const VALIDATION_ERROR = 'ValidationError';
export const BASE_ERROR = 'BaseError';

export class TwoFactorAuthenticationError extends Error {
  name: string;

  data: GroverTwoFaResponse;

  constructor(data: GroverTwoFaResponse, ...params: Array<any>) {
    super(...['Two factor authentication is required', ...params]);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TwoFactorAuthenticationError);
    }

    this.name = TWO_FA_ERROR_NAME;
    this.data = data;
  }
}

export class GrantValidationError extends Error {
  name: string;

  data: ErrorResponse;

  constructor(data: ErrorResponse, ...params: Array<any>) {
    super(...['Invalid grant', ...params]);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, GrantValidationError);
    }

    this.name = GRANT_VALIDATION_ERROR;
    this.data = data;
  }
}

export class InvalidCodeError extends Error {
  name: string;

  data: ErrorResponse;

  constructor(data: ErrorResponse, ...params: Array<any>) {
    super(...['Invalid code', ...params]);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidCodeError);
    }

    this.name = INVALID_CODE_ERROR;
    this.data = data;
  }
}

export class ValidationError extends Error {
  name: string;

  data: ValidationErrorResponse;

  constructor(data: ValidationErrorResponse, ...params: Array<any>) {
    super(...['Validation error during registration', ...params]);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidCodeError);
    }

    this.name = VALIDATION_ERROR;
    this.data = data;
  }
}

export class BaseErrors extends Error {
  name: string;

  data: string;

  constructor(data: string, ...params: Array<any>) {
    super(...['Base error', ...params]);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidCodeError);
    }

    this.name = BASE_ERROR;
    this.data = data;
  }
}
