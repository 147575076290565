import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';
import { AuthCredentials, CookieKeys } from './types';

const FOUR_HOURS_EXPIRATION_TIME_IN_SECONDS = 4 * 60 * 60;
const ONE_YEAR_EXPIRATION_TIME_IN_SECONDS = 365 * 24 * 60 * 60;
const ONE_DECADE_EXPIRATION_TIME_IN_SECONDS = 10 * 365 * 24 * 60 * 60;
const COOKIE_PATH = '/';

export const setStoreCookie = (storeCode: string): void =>
  Cookies.set(CookieKeys.storeCode, storeCode, {
    path: COOKIE_PATH,
    expires: ONE_YEAR_EXPIRATION_TIME_IN_SECONDS
  });

export const setCookieAuth = (payload: AuthCredentials): string | undefined =>
  Cookies.set(CookieKeys.apiAccessToken, payload.authToken, {
    path: COOKIE_PATH,
    expires: payload.expiresIn || FOUR_HOURS_EXPIRATION_TIME_IN_SECONDS
  });

export const saveUserIdCookie = (
  userId: string,
  expires = ONE_YEAR_EXPIRATION_TIME_IN_SECONDS
): string | undefined => Cookies.set(CookieKeys.userId, userId, { path: COOKIE_PATH, expires });

export const setDeviceIdCookie = (): void => {
  let deviceId = Cookies.get(CookieKeys.deviceId);
  if (deviceId) {
    return;
  }
  deviceId = nanoid();
  Cookies.set(CookieKeys.deviceId, deviceId, {
    path: COOKIE_PATH,
    expires: ONE_DECADE_EXPIRATION_TIME_IN_SECONDS,
    httpOnly: false
  });
};
