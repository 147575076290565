import {
  lightBaseTheme as lightSonntagTheme,
  darkBaseTheme as darkSonntagTheme
} from '@getgrover/ui';
import { Theme, ThemeBreakpoints } from './types';

const breakpoints: ThemeBreakpoints = {
  mobile: {
    min: 0,
    max: 767
  },
  tablet: {
    min: 768,
    max: 1023
  },
  desktop: {
    min: 1024,
    max: 1279
  },
  largeDesktop: {
    min: 1280,
    max: 1599
  },
  widescreen: {
    min: 1600
  }
};

const lightTheme: Theme = {
  ...lightSonntagTheme,
  mode: 'light',
  colors: {
    ...lightSonntagTheme.colors
  },
  typography: {
    ...lightSonntagTheme.typography
  },
  breakpoints
};

const darkTheme: Theme = {
  ...darkSonntagTheme,
  mode: 'dark',
  colors: {
    ...darkSonntagTheme.colors
  },
  typography: {
    ...lightSonntagTheme.typography
  },
  breakpoints
};

export { lightTheme, darkTheme };
