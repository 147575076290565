import { AxiosError } from 'axios';
import {
  TwoFactorAuthenticationError,
  GrantValidationError,
  InvalidCodeError,
  ValidationError,
  BaseErrors
} from './errors';
import { GroverTwoFaResponse, ErrorResponse, ValidationErrorResponse } from './types';

interface TwoFaResponse {
  twoFactorAuthRequired?: boolean;
  phoneLastDigits?: string;
  resendCodeTimeoutSeconds?: number;
}

const mapTwoFactorAuthData = (data: GroverTwoFaResponse | undefined): TwoFaResponse => ({
  twoFactorAuthRequired: data?.['2fa_required'],
  phoneLastDigits: data?.['phone_last_digits'],
  resendCodeTimeoutSeconds: data?.['resend_code_timeout_seconds']
});

export const handleTwoFactorAuthentication = (
  error: AxiosError<GroverTwoFaResponse>
): Promise<never> => {
  const data = error?.response?.data;
  const twoFactorAuthData = mapTwoFactorAuthData(data);
  if (!twoFactorAuthData.twoFactorAuthRequired) {
    return Promise.reject(error);
  }
  return Promise.reject(new TwoFactorAuthenticationError(data as GroverTwoFaResponse));
};

export const handleError = (error: AxiosError<ErrorResponse>): Promise<never> => {
  const data = error?.response?.data;

  if (data?.error === 'invalid_grant') {
    return Promise.reject(new GrantValidationError(data));
  }

  if (data?.base?.includes('right code')) {
    return Promise.reject(new InvalidCodeError(data));
  }

  if (!!data?.base) {
    return Promise.reject(new BaseErrors(data.base));
  }

  if (data?.error) {
    return Promise.reject(new ValidationError(data.error as ValidationErrorResponse));
  }

  return Promise.reject(error);
};
