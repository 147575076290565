import React, { Suspense, lazy, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { initAnalytics } from '@devsbb/analytics-client';
import { datadogRum } from '@datadog/browser-rum';
import { REACT_APP_SEGMENT_WRITE_KEY } from 'environment';

import './styles/globals.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client/react';
import { client } from 'services/ApolloClient';
import { setDeviceIdCookie } from 'services/cookies';
import { initRUM } from 'services/datadog';
import { createI18n } from 'translations';
import { useUser } from 'hooks/useUser';

import { lightTheme } from 'theme/themes';
import { ThemeProvider } from 'theme/ThemeContext';

const BusinessPage = lazy(() => import(/* webpackChunkName: "BusinessPage" */ 'pages/business'));

initAnalytics({
  segmentWriteKey: REACT_APP_SEGMENT_WRITE_KEY,
  app: { name: 'business-ui' }
});
initRUM();

const App = (): JSX.Element => {
  const { user, loaded: userLoaded } = useUser();

  useEffect(() => {
    setDeviceIdCookie();
    createI18n(user?.signup_language ?? 'en');
    if (!user) datadogRum.removeUser();
  }, [user]);

  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <Switch>
        {userLoaded && (
          <Route
            exact
            path="/:storeLocale/offer/:orderNumber"
            render={() => <BusinessPage user={user} />}
          />
        )}
      </Switch>
    </Suspense>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider client={client}>
        <ThemeProvider theme={lightTheme}>
          <App />
        </ThemeProvider>
      </ApolloProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
