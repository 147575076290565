import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import de from './keys/de.json';
import en from './keys/en.json';
import nl from './keys/nl.json';
import es from './keys/es.json';

export const createI18n = (lng = 'de') =>
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng,
      fallbackLng: 'de',
      interpolation: {
        escapeValue: false,
        format: (value, format) => {
          switch (format) {
            default:
              return value;
          }
        }
      },
      resources: {
        en: {
          translation: en
        },
        de: {
          translation: de
        },
        es: {
          translation: es
        },
        nl: {
          translation: nl
        }
      }
    });
